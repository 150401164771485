<template>
    <v-container fluid>

        <v-toolbar elevation="1" dark color="primary">
            <v-toolbar-title>
                Nueva referencia
            </v-toolbar-title>
        </v-toolbar>
        <v-form
            v-model="nuevaRefForm"
        >
        <v-sheet class="pa-3">
            <v-row no-gutters>

                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-radio-group
                        class="px-1"
                        v-model="nr.tipoRef"
                        :rules="[value => !!value || 'Requerido.']"
                        filled
                        rounded
                        row
                    >
                        <v-radio label="Recibida" value="r"></v-radio>
                        <v-radio label="Enviada" value="e"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <select-page
                        :registrosMax='maxUnidad'
                        :total="totalUnidad"
                        :query='queryUnidad'
                        :page='uniPage'
                        @carga='cargaUnidad'
                    >
                        <template v-slot="{dat}">
                            <v-autocomplete
                                v-model="nr.unidad"
                                class="px-1"
                                :items="itemsUnidad"
                                :loading="loadingUnidad"
                                label="Unidad"
                                :search-input.sync="queryUnidad"
                                item-text="clues"
                                :rules="[value => !!value || 'Requerido.']"
                                item-value="id"
                                no-filter
                                rounded
                                filled
                                append-icon=''
                            >
                                <template v-slot:item="dat">
                                    <div class="d-flex flex-column pb-2">
                                        <div class="font-weight-bold text-body-2">{{dat.item.clues}}-{{dat.item.nombre}}</div>
                                        <div class="text-caption">Unidad: {{dat.item.tipoInstitucion}} - {{dat.item.dependencia}}</div>
                                        <div class="text-caption">{{dat.item.municipio}} {{dat.item.estado}}</div>
                                    </div>
                                </template>

                                <template v-slot:append-item>
                                    <v-pagination
                                        v-model="uniPage"
                                        circle
                                        :length="dat.pageLength"
                                        total-visible="7"
                                    ></v-pagination>
                                </template>
                            </v-autocomplete>
                        </template>
                    </select-page>
                </v-col>

                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-dialog
                        v-model="dialogFechaSolicitud"
                        max-width="290px"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                class="white px-1"
                                v-model="nr.fechaSolicitud"
                                label="Fecha de solicitud"
                                v-bind="attrs"
                                v-on="on"
                                :rules="[value => !!value || 'Requerido.']"
                                rounded
                                filled
                                readonly
                            />
                        </template>

                        <v-date-picker
                            @input="dialogFechaSolicitud = false"
                            v-model="fechaPickerSolicitud"
                            locale="es-mx"
                            :max="estaFecha"
                            scrollable
                        />
                    </v-dialog>
                </v-col>



                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-dialog
                        v-model="dialogTimeSolicitud"
                        width="300"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                class="px-1"
                                v-model="nr.hora"
                                :rules="[value => !!value || 'Requerido.']"
                                label="HORA de solicitud"
                                v-bind="attrs"
                                v-on="on"
                                rounded
                                filled
                                readonly
                            />
                        </template>

                        <v-time-picker
                            v-model="nr.hora"
                            format="24hr"
                            @change="dialogTimeSolicitud=false"
                        ></v-time-picker>
                    </v-dialog>
                </v-col>

                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-text-field
                        class="px-1"
                        v-model="nr.medico"
                        rounded
                        filled
                        label="Médico que refiere"
                    />
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-text-field
                        class="px-1"
                        v-model="nr.paciente"
                        rounded
                        filled
                        :rules="[value => !!value || 'Requerido.']"
                        label="Nombre del paciente"
                    />
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-text-field
                        class="px-1"
                        v-model="nr.curp"
                        rounded
                        filled
                        :rules="[value => !!value || 'Requerido.']"
                        label="C.U.R.P. del paciente"
                    />
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-text-field
                        class="px-1"
                        v-model="nr.folioNac"
                        rounded
                        filled
                        label="Folio del certificado de nacimiento"
                    />
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-dialog
                        v-model="dialogFechaNacimiento"
                        max-width="290px"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                class="white px-1"
                                v-model="nr.fechaNacimiento"
                                label="Fecha de nacimiento"
                                v-bind="attrs"
                                v-on="on"
                                :rules="[value => !!value || 'Requerido.']"
                                rounded
                                filled
                                readonly
                            />
                        </template>

                        <v-date-picker
                            @input="dialogFechaNacimiento = false"
                            v-model="fechaPickerNacimiento"
                            locale="es-mx"
                            :max="estaFecha"
                            scrollable
                        />
                    </v-dialog>
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-radio-group
                        class="px-1"
                        v-model="nr.sexo"
                        label="Sexo"
                        :rules="[value => !!value || 'Requerido.']"
                        filled
                        rounded
                        row
                    >
                        <v-radio label="Masculino" value="m"></v-radio>
                        <v-radio label="Femenino" value="f"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-select
                        v-model="nr.tipo"
                        :rules="[value => !!value || 'Requerido.']"
                        class="px-1"
                        filled
                        rounded
                        :items="$store.state.catalogos.tiposReferencias"
                        label="Tipo de solicitud"
                        @change="nr.servicio=null"
                    />
                </v-col>

                <v-col v-if="nr.tipo == 2" xs="12" sm="6" md="6" lg="4" xl="4" cols="12">
                    <v-expand-x-transition>
                        <v-select
                            v-if="nr.tipo == 2"
                            v-model="nr.servicio"
                            :rules="[value => !!value || 'Requerido.']"
                            class="px-1"
                            filled
                            rounded
                            :items="$store.state.catalogos.especialidades"
                            item-text="especialidad"
                            item-value="id"
                            label="Servicio"
                            @focus="$store.dispatch('catalogos/cargaEspecialidad')"
                        />
                    </v-expand-x-transition>
                </v-col>


                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                    <v-textarea
                        class="px-1"
                        v-model="nr.diagnosticos"
                        :rules="[value => !!value || 'Requerido.']"
                        filled
                        rounded
                        label="Diagnósticos del paciente"
                    />
                </v-col>
                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                    <v-textarea
                        class="px-1"
                        v-model="nr.motivo"
                        :rules="[value => !!value || 'Requerido.']"
                        filled
                        rounded
                        label="Motivo de la solicitud"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        class="px-1"
                        v-model="nr.observaciones"
                        filled
                        rounded
                        label="Observaciones"
                    />
                </v-col>
            </v-row>

            <div class="d-flex px-4 justify-end">
                <v-btn
                    color="error"
                    text
                    @click="$router.go(-1)"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    :disabled="!nuevaRefForm"
                    @loading="loadingGuardaRef"
                    @click="guardaSol"
                >
                    Guardar
                </v-btn>
            </div>
        </v-sheet>
        </v-form>
    </v-container>
</template>

<script>
import SelectPage from '@/components/com/SelectPage'
export default {
    components:{
        'select-page':SelectPage,
    },
    data:()=>({

        nuevaRefForm:false,
        loadingGuardaRef:false,
        // EL objeto de los datos de la nueva referencia

        nr:{},

        // Para seleccionar la unidad
        queryUnidad:'',
        itemsUnidad:[],
        loadingUnidad:false,
        totalUnidad:0,
        maxUnidad:10,
        uniPage:1,

        // para la fecha de solicitud
        dialogFechaSolicitud:false,
        fechaPickerSolicitud:'',

        // para la hora de solicitud
        dialogTimeSolicitud:false,

        // para la fecha de nacimiento
        dialogFechaNacimiento:false,
        fechaPickerNacimiento:'',


    }),
    methods:{
        // Carga las unidades que refieren
        async cargaUnidad(page, query, start){
            //this.itemsUnidad=[]
            if (!this.loadingUnidad && this.queryUnidad.length>=3){
                this.loadingUnidad = true
                
                try{
                    let consulta = await this.$http({
                        url:'/catalogos/hospitalesSimple',
                        method:'GET',
                        params:{
                            page:page,
                            query:this.queryUnidad,
                            start:start,
                            limit:this.maxUnidad,
                        },
                    })
                    this.itemsUnidad=consulta.data.root
                    this.totalUnidad = consulta.data.total
                    this.loadingUnidad = false
                    this.uniPage = 1
                }catch(err){
                    this.loadingUnidad = false
                    this.$store.dispatch('security/revisaError',err)
                }
            }
        },
        async guardaSol(){

            //console.log(this.nr)

            this.loadingGuardaRef = true
            try{
                let rf = await this.$http({
                    url:'/referencias/guarda',
                    method:'POST',
                    params:this.nr
                })
                this.loadingGuardaRef = false

                if (rf.data.estado == true){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        html: 'Solicitud guardad con el folio <h3><b>'+rf.data.root+'</b></h3>',
                    })
                    this.$router.go(-1)
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingGuardaRef = false
            }
        },
    },
    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        }
    },
    watch:{
        fechaPickerNacimiento(val){
            let tm = val.split('-')
            this.nr.fechaNacimiento = tm[2]+'/'+tm[1]+'/'+tm[0]
        },
        fechaPickerSolicitud(val){
            let tm = val.split('-')
            this.nr.fechaSolicitud = tm[2]+'/'+tm[1]+'/'+tm[0]
        },
    },
}
</script>

<style>

</style>