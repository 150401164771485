<template>
    <div>
        <slot :dat="campo">
        </slot>
        
    </div>
</template>

<script>
export default {
    props:{
        query:{type:String},
        registrosMax:{type:Number, default: 0},
        total:{type:Number,default: 0},
        page:{type:Number,default: 1},
    },
    data:()=>({
    }),
    
    computed:{
        
        campo(){
            let datos = {
                pageLength:this.pageLength,
                start:this.start,
                pageA:this.pageA,
                pageDe: this.pageDe,
                page:this.page
            }
            return (datos)
            //return('El dato de entrada es: '+this.datosCampo)
        },
        pageLength(){
            return (Math.ceil(this.total / this.registrosMax));
            //return (0);
        },
        start(){
            return ((this.page -1) * this.registrosMax)
        },
        pageDe: function(){
            let pd = 0
            
            if (this.total > 0) {
                pd = ((this.page - 1) * 10)+1
            }
            
            return pd
            
        },
        pageA: function(){
            let pa = this.page * 10
            
            if (pa > this.total ){
                pa = this.total
            }
            return (pa)
        },
    },
    watch:{
        query(){
            if (this.query){
                this.$emit('carga', 1, this.query, this.start)
            }
            //this.$emit('carga', 1, this.query, this.start)
        },
        page(){
            this.$emit('carga', this.page, this.query, this.start)
        },
    }
    
}
</script>

<style>
</style>